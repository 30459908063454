const content_platforms = [
    {
      id: "09bcec97-2bba-4a84-87e1-0bc8a7ac2c26",
      handle: "@hhphu",
      href: "https://medium.com/@hhphu",
      icon: "ri-medium-line",
    },
    {
      id: "c1f2dcc9-267d-4846-8962-b81960c1644a",
      handle: "@inphuseclab",
      href: "https://www.youtube.com/@inphuseclab",
      icon: "ri-youtube-line",
    },

    
  ];

  export default content_platforms;