import React from "react";
import { client } from "../client";
import Lists from "../components/Lists";
import { useLoaderData } from "react-router-dom";

export default function HackTheBox() {
  const posts = useLoaderData();
  return <Lists postLists={posts} heading={"HackTheBox"} />;
}

export const htbLoader = async () => {
  const htb = await client.getEntries({
    "metadata.tags.sys.id[all]": "hackTheBox",
    order: "-sys.createdAt",
  });

  return htb.items;
};
