const social_list = [
    {
      id: "12d642c3-4ed9-4f90-99a1-20b5a970d991",
      name: "LinkedIn",
      href: "https://www.linkedin.com/in/hhphu/",
      handle: "@hhphu",
      icon: "ri-linkedin-box-line",
    },
    {
      id: "f3f93d7d-f4cb-43c9-9c86-e17fc72ebf93",
      name: "Github",
      href: "https://github.com/hhphu",
      handle: "@hhphu",
      icon: "ri-github-line",
    },
    
    {
      id: "5b408ee2-df89-4d7b-9cfe-303489522e86",
      name: "Youtube",
      href: "https://youtube.com/@inphuseclab",
      handle: "@inphuseclab",
      icon: "ri-youtube-line",
    },
    {
      id: "5deec2cd-2b0e-4a1c-a187-12b907a25d11",
      name: "Medium",
      href: "https://medium.com/@hhphu",
      handle: "@hhphu",
      icon: "ri-medium-line",
    },
    {
      id: "09bcec97-2bba-4a84-87e1-0bc8a7ac2c26",
      name: "X",
      href: "https://x.com/hhphu",
      handle: "@hhphu",
      icon: "ri-twitter-line",
    },
    {
      id: "79b622df-b755-48cb-888c-d044d120c8dd",
      name: "Instagram",
      href: "https://instagram.com/inphuseclab",
      handle: "@inphuseclab",
      icon: "ri-instagram-line",
    },
  ];

  export default social_list;