import React from "react";
import { client } from "../client";
import Lists from "../components/Lists";
import { useLoaderData } from "react-router-dom";

export default function Projects() {
  const projects = useLoaderData();
  return <Lists postLists={projects} heading={"Projects"} />;
}

export const projectsLoader = async () => {
  const data = await client.getEntries({
    "metadata.tags.sys.id[all]": "projects",
    order: "-sys.createdAt",
  });

  return data.items;
};
