import { Outlet } from "react-router-dom";
import { Container } from "@chakra-ui/react";
import NavBar from "../components/NavBar";

export default function RootLayout() {
  return (
    <Container as="main" maxWidth="750px">
      <NavBar />
      <Outlet />
    </Container>
  );
}
