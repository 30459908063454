import React from "react";
import { client } from "../client";
import Lists from "../components/Lists";
import { useLoaderData } from "react-router-dom";

export default function Blogs() {
  const posts = useLoaderData();
  return <Lists postLists={posts} heading={"Blog Posts"} />;
}

export const blogsLoader = async () => {
  const data = await client.getEntries({ order: "-sys.createdAt" });
  return data.items;
};
