import React from "react";
import { Heading, Text, Box } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function Lists(props) {
  <Box
    width={[
      "100%", // 0-30em
      "50%", // 30em-48em
      "25%", // 48em-62em
      "15%", // 62em+
    ]}
  />;

  let lists = props.postLists;
  const displayPosts = lists.map((post) => {
    let createdDate = new Date(post.sys.createdAt);
    let id = post.sys.id;
    const { title, description } = post.fields;
    return (
      <Link to={`/posts/${id}`} key={id}>
        <Box py={2} marginLeft={5} marginTop={2}>
          <Heading as="h4" size="md" py="1px">
            {title}
          </Heading>
          <Box width="100%" color="#8f8f8f">
            <Text fontSize="sm">
              Published {createdDate.toLocaleDateString()}
            </Text>
          </Box>
          <Box width="100%" py="1px">
            <Text fontSize="sm">{description}</Text>
          </Box>
          <Box width="100%">
            <Text fontSize="sm" fontWeight="500" color="#d38b1f">
              Read More →{" "}
            </Text>
          </Box>
        </Box>
      </Link>
    );
  });

  return (
    <Box as="section" p={10}>
      <Heading
        as="h3"
        size="xl"
        marginX={["10%", "20%", "30%", "30%"]}
        marginY="3%"
        width="100%"
      >
        {props.heading}
      </Heading>
      {displayPosts}
    </Box>
  );
}
