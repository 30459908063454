import React ,{Component} from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from 'react-router-dom';
// Components
import RootLayout from './layouts/RootLayout';
import TryHackMe, { thmLoader } from './pages/TryHackMe';
import Blogs from './pages/Blogs';
import About from './pages/About';
import Home from './pages/Home';
import Post from './pages/Post';
import CheatSheet from './pages/CheatSheet';
import HackTheBox from './pages/HackTheBox'
import Projects, { projectsLoader } from './pages/Projects';
// Loader functions
import { htbLoader } from './pages/HackTheBox';
import { blogsLoader } from './pages/Blogs';
import { postLoader } from './pages/Post';



const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout/>}>
      <Route index element ={<Home />}/>
      <Route path="about" element={<About />}/>
      <Route path="posts" >
        <Route
          index
          loader={blogsLoader}
          element={<Blogs/>}
        />
        <Route path="/posts/:id"
          loader={postLoader}
          element={<Post />}
        />
      </Route>
      <Route path="tryhackme" >
        <Route
          index
          loader={thmLoader}
          element={<TryHackMe />}
        />
      </Route>
      <Route path="hackthebox" >
        <Route
          index
          loader={htbLoader}
          element={<HackTheBox />}
        />    
      </Route>    
      <Route path="projects" >
        <Route
          index
          loader={projectsLoader}
          element={<Projects />}
        />
      </Route>  
      <Route path="cheatsheet" >
        <Route
          index
          loader={projectsLoader}
          element={<CheatSheet />}
        />
      </Route>       
    </Route>
 
  )
)

class App extends Component {
  render(){
    return (
    <ChakraProvider >
      <RouterProvider router={router} />
    </ChakraProvider>
  );
  }
}

export default App;
