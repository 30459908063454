import React, { Component } from "react";
import { client } from "../client";
import About from "./About";
import ActiveProjects from "../components/ActiveProjects";

class Home extends Component {
  state = {
    articles: [],
  };

  componentDidMount() {
    client
      .getEntries({ order: "-sys.createdAt" })
      .then((response) => {
        this.setState({ articles: response.items });
      })
      .catch(console.error);
  }

  render() {
    return (
      <>
        <About />
        <ActiveProjects />
        {/* <Box as="section" px={[10, 10, 10, 10]} py={5}>
          <Box marginLeft={5}>
            <Heading size="lg" px={[0, 0, 0, 3]} marginTop={10}>
              <Link to="/posts">Recent posts</Link>
            </Heading>
            <Heading size="lg" px={[0, 0, 0, 3]} marginTop={10}>
              <Link to="/projects">Projects</Link>
            </Heading>
            <Heading size="lg" px={[0, 0, 0, 3]} marginTop={10}>
              <Link to="/tryhackme">TryHackMe</Link>
            </Heading>
          </Box>
        </Box> */}
      </>
    );
  }
}

export default Home;
