import { Box, Heading, Image } from "@chakra-ui/react";
import React from "react";
import { client } from "../client";
import ReactMarkdown from "react-markdown";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { useLoaderData } from "react-router-dom";

export default function Post() {
  <Box
    width={[
      "100%", // 0-30em
      "50%", // 30em-48em
      "25%", // 48em-62em
      "15%", // 62em+
    ]}
  />;
  <Heading
    width={[
      "100%", // 0-30em
      "75%", // 30em-48em
      "66.5%", // 48em-62em
      "25%", // 62em+
    ]}
  />;
  <Image boxSize="66.67%" marginLeft="15%" marginY={2} />;

  const postData = useLoaderData();
  const { title, headerImage, mainText } = postData.fields;


  return (
    <Box
      as="article"
      width="100%"
      marginY="10%"
      marginRight={5}
      paddingX={["1%", "1%", "3%", "3%"]}
    >
      <Box width="100%">
        <Heading as="h4" fontSize={"3xl"} textAlign={"center"} marginLeft={[10, 8, 0, 0]}>
          {title}
        </Heading>
        
        <Image
          src={headerImage}
          boxSize="100%"
          marginLeft="1%"
          marginY={"3rem"}
          objectFit="cover"
        /> 
      </Box>
      <Box width="100%" fontSize={["sm", "sm", "md", "md"]} >
        <ReactMarkdown components={ChakraUIRenderer()} children={mainText} />
      </Box>
    </Box>
  );
}

export const postLoader = async ({ params }) => {
  const { id } = params;
  const data = await client.getEntry(id);
  return data;
};
