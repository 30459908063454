import React from "react";
import { client } from "../client";
import Lists from "../components/Lists";
import { useLoaderData } from "react-router-dom";

export default function TryHackMe() {
  const thm = useLoaderData();
  return <Lists postLists={thm} heading={"TryHackMe"} />;
}

export const thmLoader = async () => {
  const data = await client.getEntries({
    "metadata.tags.sys.id[all]": "TryHackMe",
    order: "-sys.createdAt",
  });

  return data.items;
};
