import React from "react";
import { Link } from "react-router-dom";
import { Flex } from "@chakra-ui/react";
import social_list from "../assets/contents/social-media-icons";

export default function SocialMedia() {
  const displaySocialMedia = social_list.map((social) => {
    return (
      <Link
        id={social.id}
        key={social.name}
        to={social.href}
        target="_blank"
        rel="noreferrer"
        className="profile__social-link"
      >
        <i className={social.icon}></i>
      </Link>
    );
  });
  return (
    <Flex
      justify="space-around"
      gap="2"
      wrap="wrap"
      fontSize="xl"
      fontWeight="400"
      pb="10%"
    >
      {displaySocialMedia}
    </Flex>
  );
}
