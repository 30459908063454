import React from "react";
import { Box, Heading, Table, Tr, Td, Text, Th } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export default function ActiveProjects() {
  const commonTextStyle = {
    fontSize: "14px",
    padding: "12px",
  };

  const activeProjectsList = [
    {
      id: "7385fa08-bfa8-4cd3-b647-bb34a0959995",
      name: "My Blogs",
      url: "/posts",
      description:
        "Document my Cybersecurity journey on this personal blog posts.",
    },
    {
      id: "ba1b9ccd-9897-45a0-8d5f-38cbf5e5f358",
      name: "YouTube Channel",
      url: "https://youtube.com/@inphuseclab",
      description:
        "On my YouTube channel I create short videos on various Cybersecurity topics.",
    },
    {
      id: "61626818-148b-4f3a-9662-c0585a155ed2",
      name: "Medium",
      url: "https://www.medium.com/@hhphu",
      description:
        "Post minimum three posts per week to share a wide range of topics from cybersecurity, programming, career advice and etc.",
    },
  ];

  const displayActiveProjects = activeProjectsList.map((activeProject) => {
    return (
      <Tr>
        <Th>
          <Link
            id={activeProject.id}
            key={activeProject.name}
            to={activeProject.url}
            target="blank"
            rel="noreferrer"
          >
            {activeProject.name}
          </Link>
        </Th>
        <Td style={commonTextStyle}>{activeProject.description}</Td>
      </Tr>
    );
  });
  return (
    <Box as="section" p={[5, 5, 10, 10]}>
      <Box marginLeft={5}>
        <Heading size="lg" px={3}>
          Active Projects
        </Heading>
        <Text style={commonTextStyle}>
          These are the projects I've been working on
        </Text>
        <Table>{displayActiveProjects}</Table>
      </Box>
    </Box>
  );
}
