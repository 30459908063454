import React from "react";
import { Link } from "react-router-dom";
import { Box, Heading, HStack, Flex, Text } from "@chakra-ui/react";

import content_platforms from "../assets/contents/content-platforms";

export default function About() {
  const commonTextStyle = {
    fontSize: "14px",
    padding: "12px",
  };

  const displayPlatFormStyle = {
    paddingTop: "5%",
    paddingBottom: "5%",
    paddingLeft: "40px",
    flexDirection: "column",
    justify: "space-around",
    gap: "2",
    wrap: "wrap",
    fontSize: "md",
    fontWeight: "400",
  };

  const displayContentPlatforms = content_platforms.map((platform) => {
    return (
      <Link
        id={platform.id}
        key={platform.name}
        to={platform.href}
        target="_blank"
        rel="noreferrer"
      >
        <HStack>
          <i
            className={platform.icon}
            style={{ paddingRight: "8px", fontSize: "25px" }}
          ></i>
          <Text>{platform.handle}</Text>
        </HStack>
      </Link>
    );
  });
  return (
    <Box as="section" p={[5, 5, 10, 10]}>
      <Box py={4} marginLeft={5} marginTop={2}>
        <Heading size="lg" px={3}>
          Hello World,
        </Heading>
        <Text style={commonTextStyle}>
          I'm Huy, a seasoned QA Engineer based in the US. I love technology,
          programming and gaming. I also create contents about Cybersecurity,
          ranging from Cloud Security to penetration testings. Which can be
          found here:
        </Text>
        <Flex style={displayPlatFormStyle}>{displayContentPlatforms}</Flex>
      </Box>
    </Box>
  );
}
