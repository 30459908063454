import {
  Box,
  Container,
  Image,
  VStack,
  Text,
  Flex,
  Divider,
} from "@chakra-ui/react";
import React from "react";
import { NavLink } from "react-router-dom";
import profilePic from "../assets/images/apple192.png";
import SocialMedia from "./SocialMedia";

export default function NavBar() {
  const nav_links = [
    {
      name: "HOME",
      goto: "/",
    },
    // {
    //   name: "ABOUT",
    //   goto: "about",
    // },
    {
      name: "BLOG POSTS",
      goto: "posts",
    },
    {
      name: "TRYHACKME",
      goto: "tryhackme",
    },
    {
      name: "HACKTHEBOX",
      goto: "hackthebox",
    },
    {
      name: "PROJECTS",
      goto: "projects",
    },
    {
      name: "CHEATSHEET",
      goto: "cheatsheet",
    },
  ];

  const displayedNavLinks = nav_links.map((link) => {
    return (
      <NavLink key={link.name} to={link.goto}>
        {link.name}
      </NavLink>
    );
  });

  return (
    <Container
      as="nav"
      maxW="100%"
      mt={5}
      alignItems="center"
      mx="auto"
      marginBottom={5}
    >
      <Box maxW="300px" mx="auto" mb={-6}>
        <VStack>
          <Image
            borderRadius="full"
            boxSize="75px"
            src={profilePic}
            alt="Huy Phu"
            alignItems="center"
            background="gray.200"
            objectFit="cover"
            border="1px"
          />
          <Text
            maxH="5px"
            fontSize="xl"
            fontWeight="900"
            mx="auto"
            paddingBottom="8%"
          >
            Huy Phu
          </Text>
          <SocialMedia />
        </VStack>
      </Box>

      <Divider fontWeight="800" />
      <Flex
        as="nav"
        justify="space-around"
        gap="1"
        wrap="wrap"
        py="17px"
        fontSize="xs"
        fontWeight="800"
      >
        {displayedNavLinks}
      </Flex>
      <Divider />
    </Container>
  );
}
